import React, { useState, useContext, useEffect, useRef } from "react";
import MainLayout from "../../../components/layouts/MainLayout/MainLayout";
import AsideChat from "./components/AsideChat";
import { v4 as uuidv4 } from "uuid";
import { DataContext } from "../../../context/DataContext";
import env from "../../../config/env";
import BubbleChat from "./components/BubbleChat"; // Asegúrate de importar correctamente

const ChatRoom = () => {
  const { API_URL, API_KEY } = env;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const { userData } = useContext(DataContext);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const invokeGPTService = async (
    userMessage,
    chatLog,
    userName,
    userEmail,
    sessionId,
    userInfo
  ) => {
    const postData = {
      session_id: sessionId,
      user_email: userEmail,
      user_name: userName,
      question: userMessage,
      message_hystory: chatLog.map((log) => log.text),
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
      },
      body: JSON.stringify(postData),
    });

    if (!response.ok) {
      throw new Error("Error en la solicitud a la API GPT");
    }

    const jsonResponse = await response.json();
    return jsonResponse.body;
  };

  useEffect(() => {
    if (userData) {
      const { attributes } = userData;
      setUserInfo(attributes);
      setSessionId(uuidv4());

      setChatLog([
        {
          sender: "gpt",
          text: "¡Hola! Soy tu asistente virtual. Estoy aquí para ayudarte. ¿En qué puedo asistirte hoy?",
          status: "Listo",
        },
      ]);
    }
  }, [userData]);

  useEffect(() => {
    scrollToBottom();
  }, [chatLog]);

  const handleSendMessage = async () => {
    if (message.trim() === "") return;

    const newChatLog = [
      ...chatLog,
      { sender: "user", text: message, status: "esperando..." },
      { sender: "gpt", text: "escribiendo...", status: "esperando..." },
    ];
    setChatLog(newChatLog);
    setMessage("");
    setLoading(true);

    try {
      const response = await invokeGPTService(
        message,
        chatLog,
        userInfo.name,
        userInfo.email,
        sessionId,
        userInfo
      );

      const updatedChatLog = newChatLog.map((chat) =>
        chat.text === "escribiendo..." && chat.sender === "gpt"
          ? { ...chat, text: response, status: "Listo" }
          : chat
      );

      // Actualizar el estado del mensaje del usuario a "Listo"
      const finalChatLog = updatedChatLog.map((chat) =>
        chat.status === "esperando..." && chat.sender === "user"
          ? { ...chat, status: "Listo" }
          : chat
      );

      setChatLog(finalChatLog);
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      setChatLog(newChatLog.filter((chat) => chat.text !== "escribiendo..."));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <MainLayout>
      <div className="flex" style={{ height: "calc(100vh - 74px)" }}>
        <AsideChat />
        <main className="flex flex-col flex-1 gray-app-bg">
          <div className="flex-1 p-8 overflow-y-auto space-y-4">
            <h1 className="text-2xl font-bold mb-4">Chat</h1>
            {chatLog.map((chat, index) => (
              <BubbleChat
                key={index}
                position={chat.sender === "user" ? "right" : "left"}
                message={chat.text}
                status={chat.status}
                sender={
                  chat.sender === "user"
                    ? userInfo.name.split(" ")[0]
                    : "SincronyBot"
                }
                userInfo={userInfo}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="p-8">
            <div className="flex items-center bg-white border border-gray-300 rounded-lg overflow-hidden">
              <textarea
                className="flex-1 px-4 py-6 border-none focus:outline-none resize-none rounded-none"
                rows={1}
                placeholder="Escribe tu mensaje..."
                value={message}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              ></textarea>
              <button className="p-2" onClick={handleSendMessage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
                </svg>
              </button>
            </div>
          </div>
        </main>
      </div>
    </MainLayout>
  );
};

export default ChatRoom;
