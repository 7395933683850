import React, { useContext, useState } from 'react';
import axios from 'axios';
import { DataContext } from '../../../../context/DataContext'
import env from '../../../../config/env';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import CreateUserForm from './CreateUserForm/CreateUserForm';

const CreateUsersModal = ({ activeModal, toggleActiveModal, fetchUsers }) => {

    const url = env.APP_URL
    const { accessToken } = useContext(DataContext)
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async () => {

        setIsLoading(true)

        const userUrl = `${url}/user`

        const data = {
            email,
            name,
            password
        }

        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }

        // sending a POST request to the API endpoint
        try {
            const response = await axios.post(userUrl, data, { headers })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Usuario creado con éxito',
                showConfirmButton: false,
                timer: 1500
            })
            fetchUsers()
            return response.data
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '¡Algo salió mal!',
              })
            console.log(err)
        } finally {
            setIsLoading(false)
            toggleActiveModal()
        }
    }

    // function to reset input values, if action is equal to close, user modal will be closed
    const resetFields = (action) => {
        if (action === "close") {
            toggleActiveModal()
        }
        setName("")
        setEmail("")
        setPassword("")
    }

    // Function to render loading block while getting response, otherwise it will return create users form
    const renderUserForm = () => {
        if (isLoading) {
            return (
                <div className='w-full space-y-5'>
                    <div className='w-full flex justify-center'>
                        <svg aria-hidden="true" className="w-12 h-12 mr-2 text-blue-500 animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    </div>

                    <div className='w-full flex justify-center text-sm'>
                        Creando usuario...
                    </div>
                </div>
            )
        } else {
            return <CreateUserForm 
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                resetFields={resetFields}
                handleSubmit={handleSubmit}
            />
        }
    }

    return (
        <div className={`relative z-50 ease-out duration-300 ${!activeModal ? 'opacity-0 pointer-events-none' : ''} `} aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className={`fixed inset-0 bg-modal-shadow bg-opacity-75 transition-opacity ${!activeModal ? 'opacity-0 pointer-events-none' : ''}`} style={{ visibility: activeModal ? 'visible' : 'hidden' }}></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full md:max-w-xl lg:px-8">
                        <div className={`bg-white px-4 p-6 pb-8 lg:py-10 ${!false || 'pointer-events-none'}`}>

                            <div className="flex relative w-full justify-center items-center mb-3">
                                <h2 className="text-xl font-bold">Crear usuario</h2>

                                <XMarkIcon
                                    className="w-5 h-5 lg:w-6 lg:h-6 absolute right-0 cursor-pointer hover:rotate-[90deg] transition duration-400"
                                    onClick={() => resetFields("close")}
                                />
                            </div>

                            <hr className='my-8' />
                            {/* User Form */}
                            {renderUserForm()}

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CreateUsersModal;
