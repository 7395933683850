import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState } from 'react';
import { AdminLayout } from "../../components/layouts";
import { Link } from "react-router-dom";
import Loader from "../../components/common/Loader";
import { DataContext } from "../../context/DataContext";
import { getNameInitials } from "../../utils";
const { v4: uuidv4 } = require('uuid');



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51O5r7TLstI2zswFmpfDnJJ0zBh67TISm9ZyeZa9ZRQRiY6Rf8brPXwWAF6fdLXTIG2h1Z9R54qN1uDqvN0Vir65T009IwCQtF1');


const SuscripccionComponent = () => {

    const [loading, setLoading] = useState('');
    const [message, setMessage] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [chatLog, setChatLog] = useState([]);
    const [userInfo, setUserInfo] = useState([])
    const [userInitials, setUserInitials] = useState('')
    const { userData } = useContext(DataContext)


    useEffect(() => {
        if (userData) {
            const { attributes } = userData
            // getting name initials from getNameInitials util function
            const initials = getNameInitials(attributes.name)
            setUserInfo(attributes)
            setUserInitials(initials)
        }
        // Generate sessionuuid
        const sessioID = uuidv4();
        console.log("SessionID:", sessioID)
        setSessionId(sessioID);
    }, [userData])

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    return (
        <AdminLayout title="Compra una suscripcción y recibe seguimiento personalizado y más..">

            <div className="md:w-1/2 px-5 md:px-10">
                <div className="h-2/3 flex flex-col justify-between">
                    <div>
                        <h3 className="font-bold mb-1 text-green-600">Suscripcciones</h3>
                        <p id="userName" value="{userInfo.name}" className="text-brown-400">{userInfo.name}</p>
                    </div>
                </div>
            </div>

            <div className="p-5 xl:p-7 pb-0 xl:pb-0 flex items-center animate__animated animate__fadeIn flex-col lg:flex-row">
                <div className="chatContainer bg-brown-100">

                </div>
            </div>
        </AdminLayout>

    );
}

export default SuscripccionComponent;
