
import { useParams } from "react-router-dom"

const SharedVideo = () => {

    const {videoUrl} = useParams()

    return (
        <div className="w-full h-screen flex justify-center items-center bg-gray-100 px-3">
            <video src={videoUrl} width="800" height="400" autoPlay muted controls className="rounded shadow-lg"/>
        </div>
    )
}

export default SharedVideo