
import { useCallback, useEffect, useState } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import VideotecaLogo from "../../../../../assets/icons/VideotecaLogo/VideotecaLogo";
import "./SocialCard.css"
import { Link } from "react-router-dom";
import env from "../../../../../config/env";

const SocialCard = ({ data, toggleVideoModal }) => {

    const thumbnailBucketUrl = env.AWS_THUMBNAIL_BUCKET_URL
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [bucketImageUrl, setbucketImageUrl] = useState("")

    const getVideoUrl = () => {
        // Set the bucket URL using the data's thumbnail property
        setbucketImageUrl(`${thumbnailBucketUrl}/${data?.thumbnail}`)
    }

    // Getting name shortened to fit data title in a shorter space
    const name = data?.fileName?.substring(0, 20)

    // Convert the toggleVideoModal function into a pure function to avoid re-rendering
    const handleCardClick = useCallback(() => {
        toggleVideoModal(data);
    }, [data, toggleVideoModal]);

    const renderImage = () => {
        if (data?.thumbnail === " " || data?.thumbnail === "") {
            // Render a placeholder element if thumbnail data is empty
            return (
                <div className="h-3/4 bg-gray-800 rounded-t-lg flex items-center justify-center cursor-pointer"
                    onClick={handleCardClick}>
                    <VideotecaLogo className="hover:animate-pulse" />
                </div>
            )
        } else {
            // Render the thumbnail image from the bucket URL
            return (
                <div className="h-3/4 bg-gray-800 rounded-t-lg flex items-center justify-center cursor-pointer bg-cover bg-center"
                    style={{ backgroundImage: `url(${bucketImageUrl})` }}
                    onClick={handleCardClick}>
                    <VideotecaLogo className="hover:animate-pulse" />
                </div>
            )
        }
    }

    // Function with conditional to render cardElement depending on the screen size
    const renderCardElement = () => {
        if (windowWidth >= 1024) {
            return renderImage()
        } else {
            // Render a link instead of the image for smaller screen sizes
            return (
                <Link className="h-3/4 bg-gray-800 rounded-t-lg flex items-center justify-center cursor-pointer bg-cover bg-center"
                    style={{ backgroundImage: `url(${bucketImageUrl})` }}
                    to={`/gallery/${data?.id}`}>
                    <VideotecaLogo className="hover:animate-pulse" />
                </Link>
            )
        }
    }

    useEffect(() => {
        getVideoUrl()

        // Update windowWidth on resize
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        // Attach event listener for window resize and cleanup on unmount
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    return (
        <div className="w-full h-44 lg:h-44 xl:h-48 rounded-lg flex flex-col card-width shadow-md hover:shadow-xl hover:scale-105 transition duration-500">
            <span className="font-semibold"> X (Twitter) </span>
            <span className="font-semibold"> Facebook </span>
            <span className="font-semibold"> Instagram </span>
            <span className="font-semibold"> YouTube </span>
            {/* Function to check screen width size and show or hide modal video, otherwise redirect to video Id screen */}
            {renderCardElement()}

            <div className="h-1/4 bg-white rounded-b-lg flex px-3 overflow-y-hidden">
                <div className="flex flex-col w-full text-xs justify-center text-gray-900">
                    {/* Adding conditional to show a triple dot if length of name is bigger than 20 chars only */}
                    <span className="font-semibold">{`${name}${name?.length > 20 ? '...' : ''}`} </span>
                    <span className="font-light">{data.duration}</span>
                </div>
                <div className="color-black flex items-center">
                    <EllipsisVerticalIcon className="w-5 h-5 cursor-pointer" />
                </div>
            </div>
        </div>
    );
}

export default SocialCard;
