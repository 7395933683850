import { useContext, useState, useEffect } from "react";
import React, { HTMLAttributes, HTMLProps } from 'react'
import axios from "axios";
import { DataContext } from "../../../../context/DataContext";
import env from "../../../../config/env";
import { AdminLayout } from "../../../../components/layouts";
import SocialCard from "./Card/SocialCard";
import { NotFoundIcon } from "../../../../assets/icons";
import { VideoCameraIcon } from "@heroicons/react/24/outline";
//import VideoModal from "./VideoModal/VideoModal";

// Dummy data
import { customersData } from './data/dummyData';
import { useTable, useRowSelect  } from 'react-table';

import Swal from "sweetalert2";




// Gallery component
const Gallery = () => {

    // ***Load dummy data:
    const data = React.useMemo(
        () => [
          {
            col1: 'John',
            col2: 'Doe',
            col3: 'john.doe@example.com'
          },
          {
            col1: 'Jane',
            col2: 'Doe',
            col3: 'jane.doe@example.com'
          },
          {
            col1: 'Alice',
            col2: 'Johnson',
            col3: 'alice.johnson@example.com'
          },
        ],
        []
      );

      const columns = React.useMemo(
        () => [
          {
            Header: 'First Name',
            accessor: 'col1', // accessor is the "key" in the data
          },
          {
            Header: 'Last Name',
            accessor: 'col2',
          },
          {
            Header: 'Email',
            accessor: 'col3',
          },
        ],
        []
      );
    


    const { accessToken } = useContext(DataContext);
    console.log("token:", accessToken)
    const url = env.APP_URL;

    // State variables
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [inputText, setInputText] = useState("")
    const [searchText, setSearchText] = useState("");
    const [activeModal, setActiveModal] = useState(false)
    const [activeVideoModal, setActiveVideoModal] = useState(false)
    const [videoData, setVideoData] = useState({});
    const [isUploading, setIsUploading] = useState(false);

    const toggleUploadingVideo = () => {
        setIsUploading(!isUploading)
    }

    // Function to toggle the upload modal
    const toggleUploadModal = () => {
        setActiveModal(!activeModal)
    }

    // Function to toggle the video modal and set video data
    const toggleVideoModal = (data) => {
        setActiveVideoModal(!activeVideoModal)
        setVideoData(data)
    }

    // Function to detect Enter keydown to render videoData
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            setSearchText(inputText)
        }
    }

    useEffect(() => {
        // Clear search text when inputText is empty
        if (inputText.length <= 0) {
            setSearchText('')
        }
    }, [inputText])

    useEffect(() => {
        // load functions
    }, [searchText]);

    const renderSocialCards = () => {

        <span className="font-semibold"> Publicaciones recomendadas </span>
        // Map the videos array to SocialCard components
        const mappedData = videos.map((video, index) => (
            <div
                className="lg:block lg:col-span-4 xl:col-span-3 2xl:col-span-1"
                key={`${index}-${video.name}`}
            >
                <SocialCard
                    data={video}
                    activeVideoModal={activeVideoModal}
                    toggleVideoModal={toggleVideoModal}
                />
            </div>

        ))

        // Add an extra element to the mapped data to fill the last row
        const extraElement = <div
            className="lg:block lg:col-span-4 xl:col-span-3 2xl:col-span-1"
            key="extra-element"
        >
            <div className="border border-blue-300 bg-slate-100 shadow rounded-md p-3 max-w-sm w-full mx-auto h-44">
                <div className="animate-pulse flex flex-col space-y-2">
                    <div className=" bg-slate-300 h-24 w-full rounded"></div>
                    <div className=" bg-slate-300 h-4 w-full rounded"></div>
                    <div className=" flex space-x-2">
                        <div className=" bg-slate-300 h-5 w-1/2 rounded"></div>
                        <div className=" bg-slate-300 h-5 w-1/2 rounded"></div>
                    </div>
                </div>
            </div>
        </div>

        const mappedDataWithExtraElement = [...mappedData, extraElement];

        // Return loading cards if isLoading is true
        if (isLoading) {
            const loadingCards = new Array(13).fill().map((_, i) => (
                <div
                    className="lg:block lg:col-span-4 xl:col-span-3 2xl:col-span-1"
                    key={i}
                >
                    <div className="border border-blue-300 bg-slate-100 shadow rounded-md p-3 max-w-sm w-full mx-auto h-44">
                        <div className="animate-pulse flex flex-col space-y-2">
                            <div className=" bg-slate-300 h-24 w-full rounded"></div>
                            <div className=" bg-slate-300 h-4 w-full rounded"></div>
                            <div className=" flex space-x-2">
                                <div className=" bg-slate-300 h-5 w-1/2 rounded"></div>
                                <div className=" bg-slate-300 h-5 w-1/2 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
            return loadingCards;
        }

        // Return mapped data if videos is not empty
        if (videos?.length) {

            // Return mapped data if isUploading is false withouth extra element
            if (!isUploading) {
                return mappedData
            }

            // Return mapped data if isUploading is true with extra element
            return <>{mappedDataWithExtraElement}</>;
        }

        return (
            //We have to add here code to say there are no matches
            <div className="w-full col-span-full flex flex-col items-center justify-center pt-12 md:pt-28 lg:pt-36">
                <NotFoundIcon className="md:w-56 md:h-60 lg:w-60 lg:h-64" />
                <span className="mt-5 text-md md:text-xl text-slate-600 text-center">Ups! No encontramos resultados</span>
            </div>
        )

    }




    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
      } = useTable(
        { columns, data },
        useRowSelect,
        hooks => {
          hooks.visibleColumns.push(columns => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ])
        }
      );
    
      const handleRowSelection = () => {
        console.log("Selected Rows: ", selectedFlatRows.map(row => row.values));
      }
    
      const handleClick = () => {
        console.log("Button clicked!");
      }
      
      React.useEffect(() => {
        handleRowSelection();
      }, [selectedFlatRows]);


    return (
        <AdminLayout>
            <div className="p-5 xl:p-7 pb-0 xl:pb-0 flex items-center animate__animated animate__fadeIn flex-col lg:flex-row">
                <div className="text-2xl font-semibold w-full lg:w-1/3 pb-3 lg:pb-0">
                    Proyectos de publicaciones
                </div>
                {/* searchbar */}
                <div className="text-2xl font-semibold w-full lg:w-1/3 flex justify-center">
                    <div className="flex justify-center items-center w-full">
                        <div className="w-full">
                            <div className="relative flex w-full flex-wrap items-stretch">
                                <select id="countries" defaultValue="all" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-l-md focus:ring-blue-500 focus:border-blue-500 block w-[132px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="all" >Todos</option>
                                    <option value="juez">Youtube</option>
                                    <option value="acusado">Twitter</option>
                                    <option value="demandante">Facebook</option>
                                    <option value="demandante">Instagram</option>
                                </select>
                                <input
                                    type="search"
                                    className="relative m-0 -mr-px block w-[1%] min-w-0 flex-auto border border-solid border-neutral-300 bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none bg-white"
                                    placeholder="Buscar..."
                                    aria-label="Buscar"
                                    aria-describedby="button-addon1"
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => setInputText(e.target.value)}
                                />
                                <button
                                    className="bg-blue-500 relative z-[2] flex items-center rounded-r bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                                    type="button"
                                    id="button-addon1"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    onClick={() => setSearchText(inputText)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="h-5 w-5"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* add button section */}
                <div className="w-full md:w-1/3 md:text-right mt-5 lg:mt-0">
                    <button className="w-full lg:w-auto bg-blue-500 highlight-white/20 hover:bg-blue-700 focus:outline-none focus:ring-2 inline-flex gap-2 items-center justify-center focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-12 px-6 py-2 rounded-lg transition duration-200"
                    onClick={toggleUploadModal} >
                        Crear publicaciones
                        <VideoCameraIcon className="w-5 h-5" />
                    </button>
                </div>

            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 2xl:grid-cols-5 p-5 xl:p-7 gap-4 gap-y-10 animate__animated animate__fadeIn items-center">
                {/* Render gallery card or loading cards */}
                {renderSocialCards()}
            </div>

            <div>
            <button onClick={handleClick} style={{marginBottom: '10px'}}>Click me!</button>
                <table {...getTableProps()} style={{margin: '0 auto', width: '80%'}}>
        <thead>
            {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                </th>
                ))}
            </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
            {rows.map(row => {
            prepareRow(row)
            return (
                <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                    </td>
                ))}
                </tr>
            )
            })}
        </tbody>
        </table>
            </div>

        </AdminLayout>
    );
};

export default Gallery;
