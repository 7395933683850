import React from "react";

const AsideChat = () => {
  return (
    <aside className="hidden lg:block sm:w-[361px] bg-white p-4 lg:p-8">
      {/* Aside content */}
      <h2 className="text-xl font-bold mb-4">Nuevo chat</h2>
      <ul>
        <li>Historial de conversaciones</li>
      </ul>
    </aside>
  );
};

export default AsideChat;
