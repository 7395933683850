import { Auth } from "aws-amplify";
import React, { createContext, useEffect, useState } from "react";

export const DataContext = createContext();

// Custom hook that fetches user data and access token from Cognito
const useUserData = () => {
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    // Fetch user data and set it in state
    const fetchData = async () => {
      try {
        let response = await Auth.currentAuthenticatedUser();
        setUserData(response);
        // Fetch session data and set access token in state
        const session = await Auth.currentSession();
        setAccessToken(session.accessToken.jwtToken);
      } catch (err) {
        console.log("🚀 ~ file: dataContext.js:16 ~ useUserData ~ err", err);
      }
    };
    fetchData();
  }, []);

  // Function that refreshes the access token on demand
  const refreshAccessToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      // Refresh the session using the current refresh token
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        if (err) {
          console.log("🚀 ~ error refreshing session", err);
        } else {
          console.log("🚀 ~ session refreshed");
          // Set the new access token in state
          const { accessToken } = session;
          setAccessToken(accessToken.jwtToken);
        }
      });
    } catch (err) {
      console.log("🚀 ~ error refreshing access token", err);
    }
  };

  // Set up an interval to refresh the access token every 30 minutes
  useEffect(() => {
    const intervalId = setInterval(refreshAccessToken, 30 * 60 * 1000); // 30 minutes in milliseconds
    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, []);

  return { userData, setUserData, setAccessToken, accessToken };
};

// Context provider that provides user data and access token to child components
export const DataProvider = ({ children }) => {
  const { userData, setUserData, accessToken, setAccessToken } = useUserData();
  return (
    <DataContext.Provider value={{ userData, setUserData, accessToken, setAccessToken }}>
      {children}
    </DataContext.Provider>
  );
};
