
import { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { DataContext } from "./../../../context/DataContext";
import Navbar from './Navbar/Navbar';
import Sidebar from "./Sidebar/Sidebar";


const AdminPanelLayout = ({ children, title }) => {

    const navigate = useNavigate()
    const { userData, setUserData } = useContext(DataContext)
    const [isHidden, setIsHidden] = useState(true)

    const turnToHidden = () => {
        setIsHidden(!isHidden)
    }


    // Function to signOut of cognito session
    const signOut = async(event) => {
        try{
            event.preventDefault()
            await Auth.signOut()
            setUserData(null)
        } catch(err) {
            console.log({message: err.message})
        } finally {
            navigate('/login')
        }
    }

    return (
        <div className="flex min-h-screen">
            {/* sidebar */}
            <Sidebar
                turnToHidden={turnToHidden}
                isHidden={isHidden}
                signOut={signOut}
            />
            <div className="w-full col-span-12 bg-gray-100">
                <Navbar
                    turnToHidden={turnToHidden}
                    signOut={signOut}
                />
                <div className="h-screen my-auto bg-gray-100 overflow-auto" style={{ height: 'calc(100vh - 80px)' }}>
                    {title &&
                        <div className="pt-7 pl-7 text-2xl font-semibold">{title}</div>
                    }
                    {children}
                </div>
            </div>
        </div>
    );
}

export default AdminPanelLayout;
