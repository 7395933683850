import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { containsCapitalLetters, containsLowercaseLetters, containsNumbers, containsSpecialChars, isEmail } from '../../../../utils';
import { AdminLayout } from '../../../../components/layouts';
import InputForm from '../../../../components/form/InputForm';
import ErrorAlert from '../../../../components/common/alerts/ErrorAlert';
import { CheckIcon } from '../../../../assets/icons';
import { Tooltip } from 'react-tooltip';
import env from '../../../../config/env';
import axios from 'axios';
import { DataContext } from '../../../../context/DataContext';
import Swal from 'sweetalert2';

const ChangePassword = () => {

    // Navigation variable
    const navigate = useNavigate()

    const { APP_URL: urlAPI } = env
    const { userData } = useContext(DataContext)
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(userData?.attributes?.email)
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    // password validations
    const [hasNumbers, setHasNumbers] = useState(false);
    const [hasCapitalLetters, setHasCapitalLetters] = useState(false);
    const [hasLowercaseLetters, setHasLowercaseLetters] = useState(false);
    const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);
    const [hasEightChars, setHasEightChars] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);

    // Function to check password validations
    const validatePassword = (password, confirmPassword, code) => {


        const validation = {
            hasNumbers: containsNumbers(password),
            hasCapitalLetters: containsCapitalLetters(password),
            hasLowercaseLetters: containsLowercaseLetters(password),
            hasSpecialCharacters: containsSpecialChars(password),
            hasEightCharacters: password.length >= 8,
        }

        const isValid =
            validation.hasNumbers &&
            validation.hasCapitalLetters &&
            validation.hasLowercaseLetters &&
            validation.hasSpecialCharacters &&
            validation.hasEightCharacters &&
            password === confirmPassword &&
            code.length > 2

        return { validation, isValid }
    }

    // Function to hide or animate "password not match" icon
    const getConfirmPasswordClass = (password, confirmPassword) => {
        if (confirmPassword.length < 7) {
            return 'hidden'
        }
        if (password === confirmPassword) {
            return 'hidden'
        }
        return 'animate__animated animate__fadeIn'
    }

    // api functions

    // Step 1
    const sendEmailCode = async () => {

        setLoading(true)
        const resetPasswordUrl = `${urlAPI}/reset-password`
        const data = {
            email: email
        }

        try {
            await axios.post(resetPasswordUrl, data)

            // Notification to show after send email code
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Código enviado!',
                showConfirmButton: false,
                timer: 1500
            })
            setStep(2)
        } catch (err) {
            setError(err.response.data.error.message)
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    // Step 2
    const resetPassword = async () => {

        setLoading(true)

        const changePasswordUrl = `${urlAPI}/change-password`

        const data = {
            email: email,
            code: code,
            password: password
        }

        try {
            await axios.post(changePasswordUrl, data)
            // Notification to show after send email code
            navigate('/profile')
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Contraseña actualizada!',
                showConfirmButton: false,
                timer: 1500
            })

        } catch (err) {
            setError(err.response.data.error.message)
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const isValidEmail = isEmail(email)

    const renderEmailValidation = () => {
        if (!isValidEmail && email.length > 6) {
            return (
                <small className="text-red-500">Ingresa un email válido</small>
            )
        }
    }

    const renderStepOne = () => {
        if (!loading) {
            return (
                <div className="space-y-10 lg:space-y-0">
                    <div className="flex mb-5">

                        <Link to="/profile" className="h-6 md:h-8 w-6 md:w-8 md:absolute flex items-center text-center">
                            <svg fill="none" className='w-4 h-4' stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
                            </svg>
                        </Link>

                        <div className="w-full flex justify-center text-center">
                            <h1 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                Enviar código
                            </h1>
                        </div>

                    </div>
                    <div className="space-y-4">

                        <p className="text-sm">
                            Te enviaremos un código a tu dirección de correo electrónico para restablecer tu contraseña.
                        </p>

                        {/* Email field */}
                        <div className='my-12 underline font-semibold'>
                            {email}
                        </div>

                        {renderEmailValidation()}

                        {/* Message error */}
                        {error && <ErrorAlert error={error} />}

                        <div>
                            <button
                                onClick={sendEmailCode}
                                disabled={!isValidEmail}
                                className='bg-blue-500 w-full hover:bg-blue-700 disabled:opacity-50 text-white font-bold py-3 rounded md:mt-2 transition duration-200'>
                                Enviar código
                            </button>
                        </div>

                    </div>
                </div>
            )
        }
        return (
            <div className='w-full flex justify-center h-[200px] items-center'>
                <svg aria-hidden="true" className="w-14 h-14 mr-2 text-blue-500 animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
            </div>
        )


    }

    const renderStepTwo = () => {
        if (!loading) {
            return (
                <div>
                    <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl mb-5">
                        Cambiar contraseña
                    </h1>

                    <div className="space-y-4">

                        <p className="text-sm text-gray-900 font-medium text-center">Te enviamos un código para cambiar tu contraseña. Por favor, ingrésalo en el siguiente campo.</p>

                        {/* Email input */}
                        <InputForm
                            name="code"
                            placeholder="Ingresa el código"
                            type="number"
                            title=""
                            value={code}
                            setValue={setCode}
                        />

                        <InputForm
                            name="password"
                            placeholder="••••••••"
                            type="password"
                            title="Ingresa tu nueva contraseña"
                            value={password}
                            setValue={setPassword}
                        />

                        {/* check icons section */}
                        <div className={`pl-4 ${!password ? "hidden" : "animate__animated animate__fadeIn"}`}>
                            <ul className="text-xs">
                                <li className="flex items-center">
                                    <span className="mr-1">
                                        <CheckIcon isChecked={hasEightChars} />
                                    </span>Longitud mínima de 8 caracteres
                                </li>

                                <li className="flex items-center">
                                    <span className="mr-1">
                                        <CheckIcon isChecked={hasLowercaseLetters} />
                                    </span>Al menos una minúscula (a-z)
                                </li>

                                <li className="flex items-center">
                                    <span className="mr-1">
                                        <CheckIcon isChecked={hasCapitalLetters} />
                                    </span>Al menos una mayúscula (A-Z)
                                </li>

                                <li className="flex items-center">
                                    <span className="mr-1">
                                        <CheckIcon isChecked={hasNumbers} />
                                    </span>Al menos un número (0-9)
                                </li>

                                <li className="flex items-center">
                                    <span className="mr-1">
                                        <CheckIcon isChecked={hasSpecialCharacters} />
                                    </span>
                                    <span
                                        className="underline cursor-pointer"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-place="top"
                                        data-tooltip-content=" ^ $ * . [ ] { } ( ) ? &quot; - ! @ # % &amp; / \ , &gt; &lt; ' : ; | _ ~ ` + = "
                                    >
                                        Al menos un caracter especial
                                    </span>
                                    <Tooltip id="my-tooltip" />
                                </li>
                            </ul>
                        </div>

                        <InputForm
                            name="confirmPassword"
                            placeholder="••••••••"
                            type="password"
                            title="Confirmar contraseña"
                            value={confirmPassword}
                            setValue={setConfirmPassword}
                        />

                        <div className={`pl-4 ${getConfirmPasswordClass(password, confirmPassword)}`}>
                            <ul className="text-xs">
                                <li className="flex items-center">
                                    <span className="mr-1">
                                        <CheckIcon isChecked={false} />
                                    </span>Las contraseñas no coinciden
                                </li>
                            </ul>
                        </div>

                        {/* Message error */}
                        {error && <ErrorAlert error={error} />}

                        <div>
                            <button
                                disabled={!isValidPassword}
                                onClick={resetPassword}
                                className='bg-blue-500 w-full enabled:hover:bg-blue-700 text-white font-bold py-3 rounded disabled:opacity-50 disabled:hover:none'>
                                Cambiar contraseña
                            </button>
                        </div>

                    </div>
                </div>
            )
        }
        return (
            <div className='w-full flex justify-center h-[200px] items-center'>
                <svg aria-hidden="true" className="w-14 h-14 mr-2 text-blue-500 animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
            </div>
        )
    }

    useEffect(() => {
        const { validation, isValid } = validatePassword(password, confirmPassword, code)

        setHasNumbers(validation.hasNumbers)
        setHasCapitalLetters(validation.hasCapitalLetters)
        setHasLowercaseLetters(validation.hasLowercaseLetters)
        setHasSpecialCharacters(validation.hasSpecialCharacters)
        setHasEightChars(validation.hasEightCharacters)
        setIsValidPassword(isValid)

    }, [password, confirmPassword, code])

    return (
        <AdminLayout title="Cambiar contraseña">
            <div className="xl:px-7 lg:mt-10 flex items-center animate__animated animate__fadeIn flex-col lg:flex-row">

                <section className="w-full mx-auto">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                        <div className="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-2xl xl:p-0">
                            <div className="p-8 space-y-4 md:space-y-6 md:p-12">

                                {step === 1 && renderStepOne()}

                                {step === 2 && renderStepTwo()}

                            </div>
                        </div>

                    </div>

                </section>

            </div>
        </AdminLayout>
    );
}

export default ChangePassword;
