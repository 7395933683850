import axios from "axios";
import env from "../../config/env";

const { APP_URL: url } = env

// Function to get cognito users list
export const getUsers = async (accessToken) => {
  try {
    const userUrl = `${url}/user`
    const response = await fetch(userUrl, {
      headers: {
        Authorization: "Bearer " + accessToken
      }
    })
    const { data } = await response.json()
    return data
  } catch (err) {
    console.log(err)
  }
};

// Function to delete users from cognito user list
export const deleteUser = async (email, accessToken) => {

  const userUrl = `${url}/user`

  try {
    await axios.delete(userUrl, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      },
      data: {
        email: email
      }
    })
  } catch (err) {
    console.log(err)
  }
}