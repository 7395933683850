import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { DataContext } from "../../../../../context/DataContext";

const pages = ["Mi chat", "Suscripciones"];
const settings = ["Mi perfil", "Cerrar sesión"];

const AppBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useContext(DataContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUserData(null);
      navigate("/login");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <nav className="green-app-bg">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-[74px]">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <a
                href="#app-bar-with-responsive-menu"
                className="text-white font-bold text-xl"
              >
                SincronyBot
              </a>
            </div>
            <div className="hidden md:block">
              <div className="ml-14 flex items-baseline space-x-2">
                {pages.map((page) => (
                  <a
                    key={page}
                    href="#app-bar-with-responsive-menu"
                    className="text-gray-300 hover:font-semibold hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {page}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <div className="relative">
                <div>
                  <button
                    className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    id="user-menu"
                    aria-haspopup="true"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span className="sr-only">Open user menu</span>
                    <div
                      className="h-8 w-8 rounded-full bg-gray-300"
                      style={{
                        backgroundImage: `url('https://picsum.photos/20/20')`,
                      }}
                      alt=""
                    />
                  </button>
                </div>
                {isOpen && (
                  <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                    {settings.map((setting) =>
                      setting === "Cerrar sesión" ? (
                        <button
                          key={setting}
                          onClick={signOut}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                          role="menuitem"
                        >
                          {setting}
                        </button>
                      ) : (
                        <a
                          key={setting}
                          href="#app-bar-with-responsive-menu"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                          role="menuitem"
                        >
                          {setting}
                        </a>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {pages.map((page) => (
              <a
                key={page}
                href="#app-bar-with-responsive-menu"
                className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              >
                {page}
              </a>
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                <div
                  className="h-8 w-8 rounded-full bg-gray-300"
                  style={{
                    backgroundImage: `url('https://picsum.photos/20/20')`,
                  }}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <div className="text-base font-medium leading-none text-white">
                  Remy Sharp
                </div>
                <div className="text-sm font-medium leading-none text-gray-400">
                  remy.sharp@example.com
                </div>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
              {settings.map((setting) =>
                setting === "Cerrar sesión" ? (
                  <button
                    key={setting}
                    onClick={signOut}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 w-full text-left"
                  >
                    {setting}
                  </button>
                ) : (
                  <a
                    key={setting}
                    href="#app-bar-with-responsive-menu"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 w-full text-left"
                  >
                    {setting}
                  </a>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default AppBar;
