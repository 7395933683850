
import { useEffect, useState } from "react";
import './UsersTable.css'
import { getNameInitials } from "../../../../utils";
import Swal from 'sweetalert2'
import { PencilSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { deleteUser } from "../../../../services/cognito/cognito";

const UsersTable = ({ users, isLoading, accessToken, fetchUsers, userData, toggleActiveModal }) => {

    const [formattedUsers, setFormattedUsers] = useState([])
    let myEmail = userData?.attributes?.email

    // Function to get users formatted
    const formatUsers = () => {
        const formattedUsers = [];

        //Loop to get an objects array and get structured data
        users.forEach((user) => {
            const userObj = {};
            user.Attributes.forEach((userData) => {
                userObj[userData.Name] = userData.Value;
            });
            formattedUsers.push(userObj);
        });

        setFormattedUsers(formattedUsers);
    }

    useEffect(() => {

        formatUsers()

    }, [users]);

    const renderLoadingRows = () => {
        return Array.from({ length: 3 }, (_, i) => (
            <tr className="bg-white border-b" key={i}>
                <td className="px-6 py-2">
                    <div className="h-8 w-8 bg-red flex items-center justify-center rounded-full bg-gray-400  font-bold shadow-lg animate-pulse"></div>
                </td>
                <td className="px-6 py-5 whitespace-nowrap capitalize flex space-x-3">
                    <div className="w-1/3 h-3 bg-gray-400 animate-pulse rounded-lg"></div>
                    <div className="w-1/3 h-3 bg-gray-400 animate-pulse rounded-lg"></div>
                </td>
                <td className="px-6 py-5">
                    <div className="w-3/4 h-3 bg-gray-400 animate-pulse rounded-lg"></div>
                </td>
                <td className="px-6 py-5">
                    <div className="border-violet-500 py-1 px-3 rounded bg-gray-400  font-bold animate-pulse w-1/3 h-5"></div>
                </td>
                <td className="px-6 py-5 flex space-x-3 items-center">
                    <PencilSquareIcon className="w-5 h-5 cursor-pointer" />
                    <TrashIcon className="w-5 h-5 cursor-pointer" />
                </td>
            </tr>
        ));
    };

    const renderUserDataRows = () => {

        const showDeleteModal = (email) => {
            Swal.fire({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esta acción!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí, eliminar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    deleteUser(email, accessToken)
                    fetchUsers()
                    Swal.fire(
                        '¡Eliminado!',
                        'El usuario ha sido eliminado.',
                        'success'
                    )
                }
            })
        }

        return formattedUsers?.map((user, index) => (
            <tr className="bg-white border-b" key={index}>
                <td className="px-6">
                    <div className="h-8 w-8 bg-red flex items-center justify-center rounded-full bg-violet-600 text-white font-bold shadow-lg">
                        {user.name ? getNameInitials(user.name) : "-"}
                    </div>
                </td>
                <td className="px-6 py-5 whitespace-nowrap capitalize">
                    {user.name || '-'}
                </td>
                <td className="px-6 py-5">
                    {user.email || '-'}
                </td>
                <td className="px-6 py-5">
                    <span className="border-violet-500 py-1 px-3 rounded bg-violet-400 text-white font-bold">
                        Admin
                    </span>
                </td>
                <td className="px-6 py-5 flex space-x-3 items-center">
                    <PencilSquareIcon className="w-5 h-5 text-gray-300" />
                    {/* Conditional to avoid deleting my own user */}
                    {myEmail !== user.email ?
                        <TrashIcon className="w-5 h-5 cursor-pointer hover:text-red-500 shakeElement transition duration-200 " onClick={() => showDeleteModal(user.email)} />
                        :
                        <TrashIcon className="w-5 h-5 text-gray-300" />
                    }
                </td>
            </tr>
        ));
    };

    return (
        <div className="relative overflow-x-auto w-full rounded">
            <button
                onClick={toggleActiveModal}
                className="w-full lg:w-auto bg-blue-500 highlight-white/20 hover:bg-blue-700 focus:outline-none focus:ring-2 md:max-w-[136px]
                inline-flex gap-2 items-center justify-center focus:ring-slate-400 focus:ring-offset-2 float-right mb-3 md:m-1 md:mb-5
                focus:ring-offset-slate-50 text-white font-semibold h-12 px-6 py-2 rounded-lg transition duration-200"
            >
                Agregar
                <PlusIcon className="w-5 h-5" />
            </button>
            <table className="w-full text-sm text-left text-gray-600 ">
                <thead className="text-xs text-gray-50 uppercase bg-gray-800">
                    <tr>
                        <th scope="col" className="px-6 py-4 w-2">

                        </th>
                        <th scope="col" className="px-6 py-4">
                            Nombre
                        </th>
                        <th scope="col" className="px-6 py-4">
                            Correo electrónico
                        </th>
                        <th scope="col" className="px-6 py-4">
                            Rol
                        </th>
                        <th scope="col" className="px-6 py-4">
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* function to render usersTable if is not loading users data */}
                    {!isLoading ? renderUserDataRows() : renderLoadingRows()}
                </tbody>
            </table>
        </div>
    );
}

export default UsersTable;
