import AppBar from "./components/AppBar.jsx/AppBar";

const MainLayout = ({ children }) => {
  return (
    <>
      <AppBar />
      <div className="dark-text">{children}</div>
    </>
  );
};

export default MainLayout;
