import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState } from 'react';
import { AdminLayout } from "../../components/layouts";
import { Link } from "react-router-dom";
import Loader from "../../components/common/Loader";
import { DataContext } from "../../context/DataContext";
import { getNameInitials } from "../../utils";
const { v4: uuidv4 } = require('uuid');


const invokeResponseOkService = async (userMessage, gptResponse) => {
    const API_URL = 'YOUR_ENDPOINT_URL'; // Reemplaza esto con la URL de tu servicio responseok

    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            question: userMessage,
            answer: gptResponse,
            ok: true
        })
    });

    // Puedes manejar la respuesta del servidor si es necesario.
    if (!response.ok) {
        // Gestionar errores
        console.error("Hubo un error al enviar la aprobación");
    }
};

const invokeGPTService = async (userMessage, userMessagesHistory, userName, userEmail, sessionId, formData) => {

    // Datos que deseas enviar en el cuerpo de la solicitud POST
    const postData = {
        session_id: sessionId,
        user_email: userEmail,
        user_name: userName,
        gender: formData.gender,
        user_age: formData.user_age,
        user_weight: formData.user_weight,
        user_height: formData.user_height,
        user_complexion: formData.user_complexion,
        user_religion: formData.user_religion,
        smoke: formData.smoke,
        drink_alcohol: formData.drink_alcohol,
        does_exercise: formData.does_exercise,
        use_drugs: formData.use_drugs,
        medicines: formData.medicines,
        diseases: formData.diseases,
        educational_level: formData.educational_level,
        personality: formData.personality,
        feelings: formData.feelings,
        sexual_preference: formData.sexual_preference,
        question: userMessage,
        message_hystory: userMessagesHistory,
    };
    const API_URL = 'https://iu9ozlcvo5.execute-api.us-east-1.amazonaws.com/dev/therapybot'; // Reemplaza esto con la URL de tu servicio responseok
    const response = await fetch(API_URL, {
        method: 'POST',
        mode: "cors",
        cache: "no-cache",
        headers: {
            'x-api-key': 'XYHRgAMdCS8jhBf6z8IEL9DPoNPkX4StKmCPtK59',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
    });

    if (!response.ok) {
        // Si la respuesta no es exitosa, lanzar una excepción
        throw new Error('La solicitud no fue exitosa');
    }

    const jsonResponse = await response.json();
    console.log("***Respuesta en formato json:", jsonResponse)

    // Puedes manejar la respuesta del servidor si es necesario.
    if (!jsonResponse.statusCode !== 200) {
        // Gestionar errores
        console.error("Hubo un error al enviar la aprobación");
    } else {
        console.error("La respuesta es correcta");
    }
    return jsonResponse.body
};


const ChatComponent = () => {

    const [loading, setLoading] = useState('');
    const [message, setMessage] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [chatLog, setChatLog] = useState([]);
    const [userInfo, setUserInfo] = useState([])
    const [userInitials, setUserInitials] = useState('')
    const { userData } = useContext(DataContext)

    /******************************************************************
    * Form data for profile user
    *******************************************************************/
    const [formData, setFormData] = useState({
        user_email: '',
        gender: '',
        user_age: '',
        user_weight: '',
        user_height: '',
        user_complexion: '',
        user_religion: '',
        smoke: '',
        drink_alcohol: '',
        does_exercise: '',
        use_drugs: '',
        medicines: '',
        diseases: '',
        educational_level: '',
        personality: '',
        feelings: '',
        sexual_preference: '',
    });

    useEffect(() => {

        const { attributes } = userData
        if (userData) {
            // getting name initials from getNameInitials util function
            const initials = getNameInitials(attributes.name)
            setUserInfo(attributes)
            setUserInitials(initials)
            setFormData({ ...formData, user_email: attributes.email, });
        }
        // Generate sessionuuid
        const sessioID = uuidv4();
        console.log("SessionID:", sessioID)
        setSessionId(sessioID);

        /**************************************************************
        * Get profile user and set to form
        * 
        ****************************************************************/
        fetch(`https://iu9ozlcvo5.execute-api.us-east-1.amazonaws.com/dev/client/profile?user_email=${attributes.email}`, {
            method: 'GET',
            mode: "cors",
            cache: "no-cache",
            headers: {
                'x-api-key': 'XYHRgAMdCS8jhBf6z8IEL9DPoNPkX4StKmCPtK59',
                'Content-Type': 'application/json'
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Assign values to form
                console.log("data:", data)
                setFormData({
                    user_email: data.body.user_email.S,
                    gender: data.body.gender.S,
                    user_age: data.body.user_age.S.toString(),
                    user_weight: data.body.user_weight.S.toString(),
                    user_height: data.body.user_height.S.toString(),
                    user_complexion: data.body.user_complexion.S,
                    user_religion: data.body.user_religion.S,
                    smoke: data.body.smoke.S,
                    drink_alcohol: data.body.drink_alcohol.S,
                    does_exercise: data.body.does_exercise.S,
                    use_drugs: data.body.use_drugs.S,
                    medicines: data.body.medicines.S,
                    diseases: data.body.diseases.S,
                    educational_level: data.body.educational_level.S,
                    personality: data.body.personality.S,
                    feelings: data.body.feelings.S,
                    sexual_preference: data.body.sexual_preference.S,
                });
            })
            .catch((error) => {
                console.error('Error al obtener la información del usuario:', error);
            });

    }, [userData])


    /***
     * 
     * 
     */
    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        setLoading(true);
        try {
            let userMessagesHistory;
            console.log("handleSendMessage: ", message)
            if (message.trim() !== '') {
                // Get user messages from log.
                console.log("*** ChatLog: ", chatLog)
                userMessagesHistory = chatLog.filter(item => item.sender === 'user');
                const response = await invokeGPTService(message, chatLog, userInfo.name, userInfo.email, sessionId, formData)//invokeGPTService(message);
                console.log("Gpt Response:", response);
                setChatLog([...chatLog, { sender: 'user', text: message }, { sender: 'gpt', text: response }]);
                setMessage('');
            }
        } catch (err) {
            console.log("🚀 ~ Error al invocar el api del Chat")
        } finally {
            setLoading(false);
        }
    };

    const handleThumbUp = async (userMessage, gptResponse) => {
        //await invokeResponseOkService(userMessage, gptResponse);
        console.log("handleThumbUp")
    }

    const handleThumbDown = async (userMessage, gptResponse) => {
        //await invokeResponseOkService(userMessage, gptResponse);
        console.log("handleThumbDown")
    }

    // Conditional to show loading spinner if promises are not solved yet
    /**
    if (loading) {
        return <Loader />
    }
    **/

    return (
        <AdminLayout title="TherpyBot, Tu acompañante en cualquier momento">
            <div className="md:w-1/2 px-5 md:px-10">
                <div className="h-2/3 flex flex-col justify-between">
                    <div>
                        <h3 className="font-bold mb-1 text-green-600">Nombre</h3>
                        <p id="userName" value="{userInfo.name}" className="text-brown-400">{userInfo.name}</p>
                    </div>
                </div>
            </div>

            <div className="p-5 xl:p-7 pb-0 xl:pb-0 flex items-center animate__animated animate__fadeIn flex-col lg:flex-row">
                <div className="chatContainer bg-brown-100">


                    <div className="chatLog space-y-2">

                        {chatLog.map((chat, index) => (
                            <div key={index} className={`rounded-lg p-2 ${chat.sender === 'user' ? 'bg-green-300 text-gray-900 self-end' : 'bg-green-200 text-gray-900 '}`}>
                                <p class="mb-2 font-normal flex">
                                    {chat.text}
                                    {chat.sender === 'gpt' && (
                                        <span class="flex-initial border-t-2 border-gray-400 pb-4">
                                            <br />
                                            <span onClick={() => handleThumbUp(chatLog[index - 1]?.text, chat.text)} role="img" aria-label="thumb-up" style={{ cursor: 'pointer', marginLeft: '10px' }}>
                                                👍
                                            </span>
                                            <span onClick={() => handleThumbDown(chatLog[index - 1]?.text, chat.text)} role="img" aria-label="thumb-up" style={{ cursor: 'pointer', marginLeft: '10px' }}>
                                                👎
                                            </span>
                                        </span>
                                    )}
                                </p>
                            </div>
                        ))}

                        {loading ? <p>
                            <div class="border bg-green-200 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                                <div class="animate-pulse flex space-x-4">
                                    <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                                    <div class="flex-1 space-y-6 py-1">
                                        <div class="h-2 bg-slate-200 rounded"></div>
                                        <div class="space-y-3">
                                            <div class="grid grid-cols-3 gap-4">
                                                <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                                <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                            </div>
                                            <div class="h-2 bg-slate-200 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p> : <p></p>}

                    </div>

                    <label htmlFor="chat" class="sr-only">Tú mensaje</label>
                    <div class="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">
                        <button type="button" class="inline-flex justify-center p-2 text-brown-400 rounded-lg cursor-pointer hover:text-green-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                <path fill="currentColor" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                                <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" strokeWidth="2" d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                                <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" strokeWidth="2" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                            </svg>
                            <span class="sr-only">Upload image</span>
                        </button>
                        <button type="button" class="p-2 text-brown-400 rounded-lg cursor-pointer hover:text-green-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" strokeWidth="2" d="M13.408 7.5h.01m-6.876 0h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM4.6 11a5.5 5.5 0 0 0 10.81 0H4.6Z" />
                            </svg>
                            <span class="sr-only">Add emoji</span>
                        </button>
                        <textarea id="message" onChange={handleInputChange} value={message} rows="4" cols="500" class="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tú mensaje..."></textarea>
                        <button onClick={handleSendMessage} type="submit" class="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600">
                            <svg class="w-5 h-5 rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                            </svg>
                            <span class="sr-only">Send message</span>
                        </button>
                    </div>
                </div>
            </div>
        </AdminLayout>

    );
}

export default ChatComponent;
