const Footer = () => {
  return (
    <footer className="flex-shrink-0 bg-gray-200 py-4 px-8 text-center">
      <p className="text-sm">
        &copy; 2023 SincronyBot. Todos los derechos reservados.
      </p>
    </footer>
  );
};

export default Footer;
