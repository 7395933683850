

import { AdminLayout } from '../../../components/layouts';
import { PeopleIcon } from '../../../assets/icons';
import { UsersIcon, PlayIcon, ChatBubbleLeftIcon, ListBulletIcon } from '@heroicons/react/24/outline' //https://heroicons.com/
import { Link } from 'react-router-dom';

const Dashboard = () => {

  return (

    <AdminLayout title="Panel de inicio">

      <div className="grid grid-cols-12 p-5 xl:p-7 gap-4 xl:gap-7 bg-green-400 text-white animate__animated animate__fadeIn">

        <div className="col-span-12 lg:col-span-5 h-56 xl:h-80 gap-y-4 xl:gap-7 flex flex-col">

          <Link to="/chat-gpt-custom" className="h-1/2 flex items-center pl-10 rounded-md shadow-md cursor-pointer hover:shadow-gray-400 bg-gradient-to-r from-green-600 to-green-400 hover:scale-[101%] hover:shadow-lg transform duration-300">
            <div className="h-full flex items-center justify-center gap-4">
              <div className="h-12 w-12 rounded-md bg-green-800 flex justify-center items-center">
                <ChatBubbleLeftIcon className="w-8 h-8 text-white" />
              </div>
              <div>
                <p className="text-2xl">
                  Sala de Chat
                </p>
                <p className="text-sm hidden md:block">Conversa con TerapyBot las 24 hrs. del día</p>
              </div>
            </div>
          </Link>

          <Link to="/chat-gpt-custom" className="h-1/2 flex items-center pl-10 rounded-md shadow-md cursor-pointer hover:shadow-gray-400 hover:scale-[101%] hover:shadow-lg transform duration-300 bg-gradient-to-r from-yellow-300 to-orange-400">
            <div className="h-full flex items-center justify-center gap-4">
              <div className="h-12 w-12 rounded-md bg-orange-400 flex justify-center items-center">
                <ListBulletIcon className="w-8 h-8 text-white" />
              </div>
              <div>
                <p className="text-2xl ">
                  Historial de conversaciones
                </p>
                <p className="text-sm hidden md:block">Lorem ipsum dolor sit amet consectetur.</p>
              </div>
            </div>
          </Link>

        </div>

        <div className="col-span-12 lg:col-span-5 h-56 xl:h-80 gap-y-4 xl:gap-7 flex flex-col">
          <Link to="/profile" className="col-span-12 lg:col-span-7 h-40 lg:h-56 xl:h-80 rounded-md shadow-md cursor-pointer hover:shadow-gray-400 hover:scale-[101%] hover:shadow-lg transform duration-300 bg-gradient-to-r from-yellow-400 to-orange-900 ">
            <div className='h-full flex items-center justify-center gap-4'>
              <div className="h-12 w-12 rounded-md bg-orange-900 flex justify-center items-center">
                <UsersIcon className="w-8 h-8 text-white" />
              </div>
              <div className="w-1/2 h-full flex flex-col justify-center gap-4">
                <p className="text-2xl pl-10 block text-brown-800">
                  Mi perfil
                </p>
                <p className="text-sm pl-10 hidden md:block text-brown-600">Revisa tu perfil y mantenlo siempre actualizado para una mejor experiencia</p>
              </div>
            </div>
          </Link>
        </div>
      </div>

    </AdminLayout>
  )
}

export default Dashboard;