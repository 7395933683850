
import { useContext, useEffect, useState } from "react";
import { AdminLayout } from "../../../components/layouts";
import UsersTable from "./UsersTable/UsersTable";
import { getUsers } from "../../../services/cognito/cognito";
import { DataContext } from "../../../context/DataContext";
import CreateUsersModal from "./CreateUsersModal/CreateUsersModal";


const Users = () => {

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [activeModal, setActiveModal] = useState(false)
    const { accessToken, userData } = useContext(DataContext)

    // Function to get Users from cognito service
    const fetchUsers = async () => {
        setIsLoading(true)
        try {
            const userAttributes = await getUsers(accessToken);
            setUsers(userAttributes)
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {

        fetchUsers();

    }, []);

    const toggleActiveModal = () => {
        setActiveModal(!activeModal)
    }

    return (
        <AdminLayout title="Usuarios" >

            {/* Create user form modal */}
            <CreateUsersModal
                toggleActiveModal={toggleActiveModal}
                activeModal={activeModal}
                fetchUsers={fetchUsers}
            />


            <div className="p-5 xl:px-7 mb-6 md:mb-12 pb-0 lg:pt-0 flex items-center animate__animated animate__fadeIn flex-col lg:flex-row">

                <UsersTable 
                    users={users} 
                    isLoading={isLoading} 
                    accessToken={accessToken} 
                    fetchUsers={fetchUsers} 
                    userData={userData}
                    toggleActiveModal={toggleActiveModal}
                />

            </div>
        </AdminLayout>
    );
}

export default Users;
