import { Routes, Route, Navigate } from "react-router-dom";
import { DataProvider } from "./../context/DataContext.js";
import AuthRoutes from "./AuthRoutes";
import PrivateRoute from "./PrivateRoute";
// Pages routes
import Home from "../pages/Home/Home";
import Dashboard from "../pages/AdminPanel/Dashboard/Dashboard";
// import Gallery from "../pages/AdminPanel/Gallery/Gallery.jsx";
import SocialCard from "../pages/AdminPanel/Social/SocialCard/SocialCard.jsx";

import { Login, Register, PasswordRecovery } from "../pages/auth/index.js";
// import VideoDetails from "../pages/AdminPanel/Gallery/VideoDetails/VideoDetails.jsx";
import Users from "../pages/AdminPanel/Users/Users.jsx";
import Profile from "../pages/AdminPanel/Profile/Profile.jsx";
import SharedVideo from "../pages/Public/SharedVideo/SharedVideo.jsx";
import ConfirmUser from "../pages/auth/ConfirmUser/ConfirmUser.jsx";
import ChangePassword from "../pages/AdminPanel/Profile/ChangePassword/ChangePassword.jsx";

// Chat with GPT custom model
import ChatGptCustomModel from "../pages/chat/Chat.jsx";
import Suscripccion from "../pages/suscripccion/Suscripccion.jsx";
import ChatRoom from "../pages/AdminPanel/ChatRoom/ChatRoom.jsx";

const AppRouter = () => {
  return (
    <DataProvider>
      <Routes>
        {/* Public routes */}
        <Route element={<Home />} path="/" exact />
        <Route element={<SharedVideo />} path="/sharedVideo/:videoUrl" />
        <Route path="*" element={<Navigate to="/login" replace />} />
        {/* Routes to redirect to dashboard if is authenticated, otherwise reder its child */}
        <Route element={<AuthRoutes />}>
          <Route path="/login" element={<Login />} exact />
          <Route path="/register" element={<Register />} />
          <Route path="/password/:type" element={<PasswordRecovery />} />
          <Route path="/confirm-user" element={<ConfirmUser />} />
        </Route>
        {/* Routes that render its child if is authenticated, otherwise navigate to login */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/change-password" element={<ChangePassword />} />
          <Route path="/social-card" element={<SocialCard />} />
          <Route path="/chat-gpt-custom" element={<ChatGptCustomModel />} />
          <Route path="/chatroom" element={<ChatRoom />} />
          <Route path="/suscripccion" element={<Suscripccion />} />
          {/*
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/gallery/:videoId" element={<VideoDetails />} />
                    */}
          <Route path="/users" element={<Users />} />
        </Route>
      </Routes>
    </DataProvider>
  );
};

export default AppRouter;
