import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Auth } from "aws-amplify";
import { DataContext } from "../../context/DataContext";
import { Link } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const { setUserData } = useContext(DataContext);

  // Provitional function to singOut, only for proobs
  const signOut = async () => {
    try {
      await Auth.signOut();
      setUserData(null);
    } catch (e) {
      console.log(e);
    } finally {
      navigate("/login");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow container mx-auto my-8 px-4">
        <h2 className="text-2xl font-bold mb-4">¿Qué es SincronyBot?</h2>
        <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div class="bg-green-100 p-4 rounded-xl">
            <img
              src="/therapybot-2.jpg"
              alt="Bot de terapia"
              class="w-full h-auto rounded-xl"
            />
          </div>
          <div class="bg-green-300 p-4 rounded-xl">
            <p class="text-lg mb-4">
              Nuestro bot terapéutico está diseñado para promover el bienestar
              emocional. Ofrece apoyo en forma de consejos basados en terapia
              cognitivo conductual, ofrece técnicas de manejo del estrés y
              orientación general para ayudarte a conectar con tus emociones y
              enfrentar los desafíos psicológicos. Sin embargo, es importante
              tener en cuenta que este bot no reemplaza la atención médica
              profesional ni la terapia en persona. Si estás experimentando
              problemas de salud mental graves o necesitas asesoramiento
              personalizado, te recomendamos buscar la ayuda de un profesional
              de la salud mental calificado.
            </p>
            <Link to="/dashboard" className="w-full ml-auto">
              <h2 className="text-2xl font-bold mb-4 bg-green-300 text-green-800 py-2 px-4 rounded hover:bg-green-400 hover:text-green-900 text-center">
                Ingresar
              </h2>
            </Link>
          </div>
        </div>
        <ul className="list-disc list-inside">
          <li className="mb-2">
            <span className="font-semibold text-green-600">
              Disponibilidad 24/7:
            </span>{" "}
            <p class="font-sans ...">
              Los bots pueden estar disponibles en cualquier momento del día o
              de la noche, lo que permite a los usuarios acceder a apoyo
              emocional cuando más lo necesiten, incluso fuera del horario de
              atención tradicional.
            </p>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-green-600">
              Acceso inmediato:
            </span>{" "}
            <p class="font-sans ...">
              Los usuarios pueden obtener respuestas rápidas a sus preguntas y
              preocupaciones, lo que puede ser especialmente útil en momentos de
              crisis o ansiedad.
            </p>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-green-600">
              Confidencialidad:
            </span>{" "}
            <p class="font-sans ...">
              Los usuarios pueden sentirse más cómodos compartiendo sus
              pensamientos y sentimientos con un bot, ya que no tienen que
              preocuparse por el juicio o la falta de privacidad que podría
              experimentarse en una conversación cara a cara.
            </p>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-green-600">
              Seguimiento constante:
            </span>{" "}
            <p class="font-sans ...">
              SincronyBot puede realizar un seguimiento de las conversaciones
              anteriores, lo que les permite proporcionar apoyo continuo y
              personalizado a lo largo del tiempo.
            </p>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-green-600">
              Reducción del estigma:
            </span>{" "}
            <p class="font-sans ...">
              Utilizar un bot terapéutico puede ayudar a reducir el estigma
              asociado con los problemas de salud mental, ya que brinda a los
              usuarios una forma discreta de buscar ayuda.
            </p>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-green-600">
              Desarrollo personal:
            </span>{" "}
            <p class="font-sans ...">
              SincronyBot pueden ofrecer ejercicios de autocuidado y desarrollo
              personal que ayudan a los usuarios a mejorar su bienestar
              emocional a largo plazo.
            </p>
          </li>
          <li className="mb-2">
            <span className="font-semibold text-green-600">
              Acceso temprano a la ayuda:
            </span>{" "}
            <p class="font-sans ...">
              SincronyBot puede identificar signos tempranos de problemas de
              salud mental y derivar a los usuarios a profesionales de la salud
              mental cuando sea necesario, lo que puede contribuir a una
              intervención más temprana y efectiva.
            </p>
          </li>
        </ul>
        <br />
        ¡Únete a nuestra plataforma de apoyo emocional 😊🌟! Estamos aquí las
        24/7 para brindarte apoyo y recursos para tu bienestar emocional. No
        estás solo en esto. ¡Entra ahora y cuida de ti mismo! 💪💖
        #BienestarEmocional
        <br />
        <Link to="/chatroom" className="w-full ml-auto">
          <h2 className="text-2xl font-bold mb-4">Ingresar</h2>
        </Link>
        <br />
        <button className="btn" onClick={signOut}>
          Cerrar sesión
        </button>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
