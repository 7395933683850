
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import env from '../../../config/env';
import { useNavigate } from 'react-router-dom';
import bgImage from '../../../assets/images/bg-login.png';
import InputForm from '../../../components/form/InputForm';
import ErrorAlert from '../../../components/common/alerts/ErrorAlert';
import Loader from '../../../components/common/Loader';

const ConfirmUser = () => {

    const navigate = useNavigate()

    const url = env.APP_URL
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState('')
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0);

    const params = {
        email: email,
        code: code,
    };

    const handleSubmit = async () => {
        setLoading(true)
        const verifyUrl = `${url}/verify`;
        try {
            const response = await axios.post(verifyUrl, params);
            setStep(2)
            return response.data;
        } catch (error) {
            setError(error.response.data.error.message)
        } finally {
            setLoading(false)
        }
    };

    // Function to fill progress bar and navigate to login route
    useEffect(() => {
        if (step === 2) {
            const intervalId = setInterval(() => {
                setProgress(prevProgress => prevProgress + 1);
            }, 40);
            if (progress === 100) {
                navigate('/password/change-password');
            }
            return () => clearInterval(intervalId);
        }
    }, [step, progress, navigate]);

    // Conditional to show loading spinner if promises are not solved yet
    if (loading) {
        return <Loader />
    }

    return (
        <div className="flex h-screen">
            <section className="w-full lg:w-1/2 h-full mx-auto">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">

                            {step === 1 &&
                                (
                                    <div className="space-y-10 lg:space-y-0">
                                        <div className="flex mb-8">
                                            <div className="w-full flex justify-center text-center">
                                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                                    Confirma tu correo electrónico
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="space-y-4">
                                            <p className="text-xs">
                                                Ingresa tu correo electrónico y el código de confirmación que te enviamos al momento de crear tu cuenta. Si no encuentras el correo, revisa tu bandeja de entrada (incluyendo el spam) o solicita un nuevo código de confirmación.
                                            </p>
                                            <hr />
                                            <InputForm
                                                name="email"
                                                placeholder="john_doe@example.com"
                                                type="email"
                                                title="Correo electrónico"
                                                value={email}
                                                setValue={setEmail}
                                            />
                                            <InputForm
                                                name="code"
                                                placeholder="Ingresa el código"
                                                type="number"
                                                title="Código"
                                                value={code}
                                                setValue={setCode}
                                            />
                                            {/* Message error */}
                                            {error && <ErrorAlert error={error} />}
                                            <div>
                                                <button
                                                    onClick={handleSubmit}
                                                    className="bg-blue-500 w-full enabled:hover:bg-blue-700 disabled:opacity-50 text-white font-bold py-3 rounded"
                                                >
                                                    Enviar
                                                </button>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                )
                            }
                            {step === 2 &&
                                (
                                    <div className="text-center py-5 animate__animated animate__fadeIn">
                                        <span className="text-2xl font-extrabold text-gray-900">¡Confirmación Exitosa!</span>
                                        <div className="flex justify-center my-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-28 h-28 fill-blue-500 animate__animated animate__flip">
                                                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <p className="text-sm mb-7 text-gray-900">Tu correo electrónico ha sido confirmado exitósamente</p>

                                        {/* Progress bar */}
                                        <div className="h-3 w-full mb-2 bg-gray-300 rounded-md overflow-hidden">
                                            <div
                                                className="h-full bg-blue-500"
                                                style={{ width: `${progress}%` }}
                                            ></div>
                                        </div>
                                        <p className="text-xs text-gray-900">En breve serás redirigido a la pantalla de restablecimiento de contraseña.</p>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </section>
            <section
                className="bg-cover bg-no-repeat w-1/2 h-full hidden lg:block"
                style={{ backgroundImage: `url(${bgImage})` }}
            ></section>
        </div>
    );
};

export default ConfirmUser;
