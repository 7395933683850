import DOMPurify from "dompurify";
import React from "react";

const BubbleChat = (props) => {
  const { position, message, status, sender, userInfo } = props;

  const sanitizedMessage = DOMPurify.sanitize(message, {
    ALLOWED_TAGS: [],
    KEEP_CONTENT: true,
  });

  const isLeft = position === "left" || position === "";

  return (
    <div
      className={`flex items-start ${
        isLeft ? "gap-2.5" : "flex-row-reverse gap-2.5"
      }`}
    >
      <div
        className="w-8 h-8 rounded-full bg-gray-300"
        style={{
          backgroundImage: `url('${
            isLeft
              ? "https://picsum.photos/20/20"
              : "https://picsum.photos/21/21"
          }')`,
        }}
        alt="Profile"
      />
      <div
        className={`flex flex-col w-full max-w-[477px] leading-1.5 p-4 ${
          isLeft
            ? "bg-white text-gray-900 rounded-e-xl rounded-es-xl animate-fade-in"
            : "green-light-app-bg text-white rounded-s-xl rounded-es-xl rounded-br-xl"
        }`}
      >
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <span
            className={`text-sm font-semibold capitalize ${
              isLeft ? "text-gray-700" : "text-white"
            }`}
          >
            {isLeft ? "SincronyBot" : userInfo.name.split(" ")[0]}
          </span>
          <span
            className={`text-sm font-light ${
              isLeft ? "text-gray-400" : "text-gray-300"
            }`}
          >
            {new Date().toLocaleTimeString()}
          </span>
        </div>
        <p
          className={`text-sm font-normal py-2.5 ${
            isLeft ? "text-gray-900" : "text-white"
          }`}
        >
          {sanitizedMessage}
        </p>
        <span
          className={`text-sm font-light ${
            isLeft ? "text-gray-400" : "text-gray-300"
          }`}
        >
          {status}
        </span>
      </div>
    </div>
  );
};

export default BubbleChat;
