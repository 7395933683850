
import InputForm from "../../../../../components/form/InputForm";
import { Tooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import { containsCapitalLetters, containsLowercaseLetters, containsNumbers, containsSpecialChars, isEmail } from "../../../../../utils";
import { CheckIcon } from "../../../../../assets/icons";

const CreateUserForm = ({ name, setName, email, setEmail, password, setPassword, resetFields, handleSubmit }) => {

    // password validations
    const [isValid, setIsValid] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [hasNumbers, setHasNumbers] = useState(false);
    const [hasCapitalLetters, setHasCapitalLetters] = useState(false);
    const [hasLowercaseLetters, setHasLowercaseLetters] = useState(false);
    const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);
    const [hasEightChars, setHasEightChars] = useState(false);

    // Function to check password validations
    const validatePassword = (password, email, name) => {
        const validation = {
            hasNumbers: containsNumbers(password),
            hasCapitalLetters: containsCapitalLetters(password),
            hasLowercaseLetters: containsLowercaseLetters(password),
            hasSpecialCharacters: containsSpecialChars(password),
            hasEightCharacters: password.length >= 8,
            isEmail: isEmail(email)
        }

        const isValid = 
            validation.hasNumbers &&
            validation.hasCapitalLetters &&
            validation.hasLowercaseLetters &&
            validation.hasSpecialCharacters &&
            validation.hasEightCharacters && 
            validation.isEmail &&
            email.length >= 6 &&
            name.length >= 6

        return { validation, isValid }
    }

    useEffect(() => {
        const { validation, isValid } = validatePassword(password, email, name)

        setHasNumbers(validation.hasNumbers)
        setHasCapitalLetters(validation.hasCapitalLetters)
        setHasLowercaseLetters(validation.hasLowercaseLetters)
        setHasSpecialCharacters(validation.hasSpecialCharacters)
        setHasEightChars(validation.hasEightCharacters)
        setIsValidEmail(validation.isEmail)
        setIsValid(isValid)


    }, [password, name, email])

    // Function to render email verification message
    const renderEmailValidation = () => {
        if(!isValidEmail && email.length > 6){
            return (
                <small className="text-red-500">Ingresa un email válido</small>
            )
        }
    }

    return (
        <div className="space-y-10 lg:space-y-0">
            <div className="space-y-4">

                <InputForm
                    name="name"
                    placeholder="John Doe"
                    type="text"
                    title="Nombre completo"
                    value={name}
                    setValue={setName}
                />
                { (name.length < 6 && name.length > 0) &&
                    <small className="text-red-500">Debe contener al menos 6 caractéres</small>
                }
                <InputForm
                    name="email"
                    placeholder="john@email.com"
                    type="email"
                    title="Correo electrónico"
                    value={email}
                    setValue={setEmail}
                />
                {renderEmailValidation()}
                <InputForm
                    name="password"
                    placeholder="••••••••"
                    type="password"
                    title="Contraseña"
                    value={password}
                    setValue={setPassword}
                />

                {/* check icons section */}
                <div className={`pl-4 ${!password ? "hidden" : "animate__animated animate__fadeIn"}`}>
                    <ul className="text-xs">
                        <li className="flex items-center">
                            <span className="mr-1">
                                <CheckIcon isChecked={hasEightChars} />
                            </span>Longitud mínima de 8 caracteres
                        </li>

                        <li className="flex items-center">
                            <span className="mr-1">
                                <CheckIcon isChecked={hasLowercaseLetters} />
                            </span>Al menos una minúscula (a-z)
                        </li>

                        <li className="flex items-center">
                            <span className="mr-1">
                                <CheckIcon isChecked={hasCapitalLetters} />
                            </span>Al menos una mayúscula (A-Z)
                        </li>

                        <li className="flex items-center">
                            <span className="mr-1">
                                <CheckIcon isChecked={hasNumbers} />
                            </span>Al menos un número (0-9)
                        </li>

                        <li className="flex items-center">
                            <span className="mr-1">
                                <CheckIcon isChecked={hasSpecialCharacters} />
                            </span>
                            <span
                                className="underline cursor-pointer"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-place="top"
                                data-tooltip-content=" ^ $ * . [ ] { } ( ) ? &quot; - ! @ # % &amp; / \ , &gt; &lt; ' : ; | _ ~ ` + = "
                            >
                                Al menos un caracter especial
                            </span>
                            <Tooltip id="my-tooltip" />
                        </li>
                    </ul>
                </div>

                <div className='md:flex space-y-3 md:space-y-0 md:space-x-4 md:pt-2'>

                    <button
                        disabled={!true}
                        onClick={resetFields}
                        className='bg-white w-full enabled:hover:bg-gray-100 text-gray-900 border border-gray-400
                                font-bold py-3 rounded disabled:opacity-50 disabled:hover:none transition duration-200'>
                        Limpiar
                    </button>
                    <button
                        disabled={!isValid}
                        onClick={handleSubmit}
                        className='bg-blue-500 w-full enabled:hover:bg-blue-700 text-white font-bold py-3 rounded 
                                disabled:opacity-50 disabled:hover:none transition duration-200'
                    >
                        Crear usuario
                    </button>
                </div>

            </div>

        </div>
    );
}

export default CreateUserForm;
