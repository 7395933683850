
const VideotecaLogo = ({className}) => {
    return (
        <svg width="110" height="30" viewBox="0 0 110 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${className}`}>
            <g filter="url(#filter0_d_1382_2093)">
                <path d="M50.5 19.5L57.5 15L50.5 10.5V19.5ZM53 25C51.6167 25 50.3167 24.7373 49.1 24.212C47.8833 23.6867 46.825 22.9743 45.925 22.075C45.025 21.175 44.3127 20.1167 43.788 18.9C43.2633 17.6833 43.0007 16.3833 43 15C43 13.6167 43.2627 12.3167 43.788 11.1C44.3133 9.88333 45.0257 8.825 45.925 7.925C46.825 7.025 47.8833 6.31267 49.1 5.788C50.3167 5.26333 51.6167 5.00067 53 5C54.3833 5 55.6833 5.26267 56.9 5.788C58.1167 6.31333 59.175 7.02567 60.075 7.925C60.975 8.825 61.6877 9.88333 62.213 11.1C62.7383 12.3167 63.0007 13.6167 63 15C63 16.3833 62.7373 17.6833 62.212 18.9C61.6867 20.1167 60.9743 21.175 60.075 22.075C59.175 22.975 58.1167 23.6877 56.9 24.213C55.6833 24.7383 54.3833 25.0007 53 25Z" fill="#4188F2" />
            </g>
            <g filter="url(#filter1_d_1382_2093)">
                <path d="M74.805 9.425V11.765H71.985V20H69.045V11.765H66.255V9.425H74.805ZM78.8327 11.78V13.505H82.2077V15.74H78.8327V17.645H82.6577V20H75.8927V9.425H82.6577V11.78H78.8327ZM83.7505 14.69C83.7505 13.65 83.9655 12.725 84.3955 11.915C84.8355 11.095 85.4555 10.46 86.2555 10.01C87.0555 9.55 87.9755 9.32 89.0155 9.32C90.3355 9.32 91.4455 9.675 92.3455 10.385C93.2455 11.085 93.8255 12.04 94.0855 13.25H90.9205C90.7305 12.85 90.4655 12.545 90.1255 12.335C89.7955 12.125 89.4105 12.02 88.9705 12.02C88.2905 12.02 87.7455 12.265 87.3355 12.755C86.9355 13.235 86.7355 13.88 86.7355 14.69C86.7355 15.51 86.9355 16.165 87.3355 16.655C87.7455 17.135 88.2905 17.375 88.9705 17.375C89.4105 17.375 89.7955 17.27 90.1255 17.06C90.4655 16.85 90.7305 16.545 90.9205 16.145H94.0855C93.8255 17.355 93.2455 18.315 92.3455 19.025C91.4455 19.725 90.3355 20.075 89.0155 20.075C87.9755 20.075 87.0555 19.85 86.2555 19.4C85.4555 18.94 84.8355 18.305 84.3955 17.495C83.9655 16.675 83.7505 15.74 83.7505 14.69ZM102.168 18.275H98.4177L97.8477 20H94.7577L98.6127 9.425H102.003L105.843 20H102.738L102.168 18.275ZM101.433 16.025L100.293 12.605L99.1677 16.025H101.433Z" fill="white" />
            </g>
            <g filter="url(#filter2_d_1382_2093)">
                <path d="M15.13 9.425L11.485 20H7.72L4.06 9.425H7.21L9.61 17.06L11.995 9.425H15.13ZM18.9861 9.425V20H16.0461V9.425H18.9861ZM24.8453 9.425C25.9553 9.425 26.9253 9.65 27.7553 10.1C28.5953 10.54 29.2403 11.16 29.6903 11.96C30.1403 12.76 30.3653 13.675 30.3653 14.705C30.3653 15.725 30.1353 16.635 29.6753 17.435C29.2253 18.235 28.5803 18.865 27.7403 19.325C26.9103 19.775 25.9453 20 24.8453 20H20.6603V9.425H24.8453ZM24.6203 17.405C25.4803 17.405 26.1553 17.17 26.6453 16.7C27.1353 16.23 27.3803 15.565 27.3803 14.705C27.3803 13.835 27.1353 13.165 26.6453 12.695C26.1553 12.215 25.4803 11.975 24.6203 11.975H23.6003V17.405H24.6203ZM34.6306 11.78V13.505H38.0056V15.74H34.6306V17.645H38.4556V20H31.6906V9.425H38.4556V11.78H34.6306Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_1382_2093" x="38" y="0" width="30" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.391024 0 0 0 0 0.509416 0 0 0 0 0.929167 0 0 0 0.8 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1382_2093" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1382_2093" result="shape" />
                </filter>
                <filter id="filter1_d_1382_2093" x="62.2559" y="6.32031" width="47.5859" height="18.7549" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.392157 0 0 0 0 0.509804 0 0 0 0 0.929412 0 0 0 0.8 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1382_2093" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1382_2093" result="shape" />
                </filter>
                <filter id="filter2_d_1382_2093" x="0.0605469" y="6.4248" width="42.3945" height="18.5752" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.392157 0 0 0 0 0.509804 0 0 0 0 0.929412 0 0 0 0.8 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1382_2093" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1382_2093" result="shape" />
                </filter>
            </defs>
        </svg>

    );
}

export default VideotecaLogo;
